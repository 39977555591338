import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'
import { Container, DeleteModal, TopBar } from '../../components/common'
import Main from '../../components/common/Main'
import { Button, ProfileSkeleton, TextField } from '../../components/ui'
import { IUser } from '../../types/user'
import api from '../../utils/api'
import { useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { deleteUser } from '../../store/actions/user'
import { useSelector } from 'react-redux'

export default function UserViewPage() {
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const auth = useSelector((state: RootState) => state.auth)
  const [user, setUser] = useState<IUser | null>(null)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  async function fetchUser() {
    const response = await api.get(`/users/${id}`)
    setUser(response.data.user)
  }

  async function handleDelete() {
    const response = await dispatch(deleteUser(user?.id))
    if (response.meta.requestStatus === 'fulfilled') {
      navigate('/usuarios')
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if (user) {
      setValue('name', user.name)
      setValue('email', user.email)
    }
  }, [user])

  return (
    <>
      <TopBar title="Detalhes do Usuário" />
      <Main>
        <Container>
          <div className="max-w-md">
            {user ? (
              <form>
                <TextField
                  name="name"
                  label="Nome"
                  required
                  error={errors?.name && 'Informe o nome'}
                  register={register}
                />
                <TextField
                  name="email"
                  label="Email"
                  required
                  error={errors?.name && 'Informe o nome'}
                  register={register}
                />
                <div className="mt-6 flex items-center justify-between">
                  <Button variant="primary">Salvar</Button>
                  {user.id !== auth.user?.id && (
                    <button
                      onClick={() => setDeleteModal(true)}
                      type="button"
                      className="rounded-md bg-red-100 p-2 text-red-500 hover:text-red-600"
                    >
                      <MdDelete size={20} />
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <ProfileSkeleton />
            )}
          </div>
        </Container>
      </Main>
      <DeleteModal
        onSubmit={handleDelete}
        description="A exclusão desse usuário é irreversivel"
        title="Deseja excluir esse usuário?"
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </>
  )
}
