import { createContact, deleteContact, updateContact } from './../actions/contact';
import { createSlice } from '@reduxjs/toolkit'
import { IContact } from '../../types/contact'
import { queryContacts } from '../actions/contact'

interface InitialState {
  loading: boolean
  contacts: IContact[]
  totalPages: number
  totalResults: number
}

const initialState: InitialState = {
  loading: false,
  contacts: [],
  totalPages: 0,
  totalResults: 0
}

export const ContactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createContact.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(createContact.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.contacts]
        arr.unshift(payload.contact)
        state.contacts = arr
      }),
      builder.addCase(createContact.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(queryContacts.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(queryContacts.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.contacts = payload.results
        state.totalPages = payload.totalPages
        state.totalResults = payload.totalResults
      }),
      builder.addCase(queryContacts.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(updateContact.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(updateContact.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.contacts]
        const idx = state.contacts.findIndex((contact: IContact) => contact.id === payload.contact.id)
        arr[idx] = payload.contact
        state.contacts = arr
      }),
      builder.addCase(updateContact.rejected, (state: any) => {
        state.loading = false
      })

      builder.addCase(deleteContact.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(deleteContact.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.contacts]
        const idx = state.contacts.findIndex((contact: IContact) => contact.id === payload.id)
        arr.splice(idx, 1)
        state.contacts = arr
      }),
      builder.addCase(deleteContact.rejected, (state: any) => {
        state.loading = false
      })
  },
})

export const contactActions = ContactSlice.actions

export default ContactSlice
