import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Provider } from 'react-redux'
import { Alert } from './components/common'
import Router from './routes'
import store, { AppDispatch, RootState } from './store'
import { getMe } from './store/actions/auth'
import './styles/global.css'

export default function AppProvider() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

function App() {
  return (
    <>
      <Router />
      <Alert />
    </>
  )
}
