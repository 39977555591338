import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AdminLayout, SettingLayout } from '../components/common'
import {
  ContactsPage,
  ContactViewPage,
  DashboardPage,
  FunnelsPage,
  LoginPage,
  ProfilePage,
  ReminderViewPage,
  ReplyViewPage,
  CompanySettingsPage,
  UsersPage,
  UserViewPage,
  NewPassword,
  NotificationsPage,
} from '../pages'
import { FunnelViewPage } from '../pages/FunnelViewPage'
import { RemindersPage } from '../pages/RemindersPage'
import { RepliesPage } from '../pages/RepliesPage'
import PrivateRoute from './private'
import PublicRoute from './public'

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/entrar"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<DashboardPage />} />
          <Route element={<ContactsPage />} path="/contatos" />
          <Route element={<ContactViewPage />} path="/contatos/v/:id" />
          <Route element={<FunnelsPage />} path="/funil" />
          <Route element={<FunnelViewPage />} path="/funil/v/:id" />
          <Route element={<UsersPage />} path="/usuarios" />
          <Route element={<UserViewPage />} path="/usuarios/v/:id" />
          <Route element={<RepliesPage />} path="/respostas" />
          <Route element={<ReplyViewPage />} path="/respostas/v/:id" />
          <Route element={<RemindersPage />} path="/lembretes" />
          <Route element={<ReminderViewPage />} path="/lembretes/v/:id" />
          <Route element={<ProfilePage />} path="/meu-perfil" />
          <Route element={<NotificationsPage />} path="/notificacoes" />
          <Route element={<SettingLayout />} path="/configuracoes">
            <Route path="empresa" element={<CompanySettingsPage />} />
          </Route>
          <Route element={<NewPassword />} path="/nova-senha" />
        </Route>
        <Route element={<div>404</div>} path="*" />
      </Routes>
    </BrowserRouter>
  )
}
