import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const createContact = createAsyncThunk(
  'contact/create',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.post('/contacts', input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const queryContacts = createAsyncThunk(
  'contact/query',
  async (params: any, { rejectWithValue }: any) => {
    try {
      const response = await api.get('/contacts', { params })
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const updateContact = createAsyncThunk(
  'contact/update',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.put(`/contacts/${input.id}`, input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)


export const deleteContact = createAsyncThunk(
  'contact/delete',
  async (id: any, { rejectWithValue }: any) => {
    try {
      await api.delete(`/contacts/${id}`)
      return { id }
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
