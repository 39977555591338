import { useNavigate } from 'react-router-dom'
import { IUser } from '../../../../types/user'

type UsersTableProps = {
  users: IUser[]
}

export default function UsersTable({ users }: UsersTableProps) {
  const navigate = useNavigate()
  return (
    <div>
      <table className="w-full table-auto">
        <thead>
          <tr className="border-b text-left">
            <th>Nome</th>
            <th>Email</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id} className="hover:bg-gray-100">
              <td className="py-4 text-sm">
                <div onClick={() => navigate(`/usuarios/v/${user.id}`)}>
                  <span className="cursor-pointer font-bold">
                    {user.name}
                  </span>
                </div>
              </td>
              <td className="py-4 text-sm">
                <div className="flex">
                  <span>{user.email}</span>
                </div>
              </td>
              <td className="py-4 text-sm">
                <div className="flex"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
