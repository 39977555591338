import React, { useState } from 'react'
import cls from 'classnames'

type Props = {
  page: number
  limit: number
  totalPages: number
  totalResults: number
  setCurrentPage(n: number): void
}

export default function Pagination({
  page,
  limit,
  totalPages,
  totalResults,
  setCurrentPage,
}: Props) {
  const pageNumberLimit = 5
  const [maxPageLimit, setMaxPageLimit] = useState(5)
  const [minPageLimit, setMinPageLimit] = useState(0)

  const pageNumbers = []

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i)
  }

  const onPrevClick = () => {
    if (page === 1) return
    if ((page - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit)
      setMinPageLimit(minPageLimit - pageNumberLimit)
    }
    setCurrentPage(page - 1)
  }

  const onNextClick = () => {
    if (page === totalPages) return
    if (page + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit)
      setMinPageLimit(minPageLimit + pageNumberLimit)
    }
    setCurrentPage(page + 1)
  }

  return totalPages > 1 ? (
    <div className="mt-6 flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="flex space-x-3">
          {/* <p className="text-sm text-gray-700">
            Exibindo
            <span className="font-medium">1</span>
            de
            <span className="font-medium">10</span>
            para
            <span className="font-medium">97</span>
            resultados
          </p> */}
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={onPrevClick}
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Anterior</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            {pageNumbers.map(n => {
              if (n <= maxPageLimit && n > minPageLimit) {
                return (
                  <button
                    onClick={() => setCurrentPage(n)}
                    aria-current="page"
                    className={cls("relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20", n === page && "text-indigo-600 border-indigo-500 bg-indigo-50")}
                  >
                    {n}
                  </button>
                )
              }
            })}
            <button
              onClick={onNextClick}
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Próximo</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  ) : null
}
