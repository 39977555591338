import { createFunnel, deleteFunnel, updateFunnel } from './../actions/funnel';
import { createSlice } from '@reduxjs/toolkit'
import { IFunnel } from '../../types/funnel'
import { queryFunnels } from '../actions/funnel'

interface InitialState {
  loading: boolean
  funnels: IFunnel[]
}

const initialState: InitialState = {
  loading: false,
  funnels: []
}

export const FunnelSlice = createSlice({
  name: 'funnel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createFunnel.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(createFunnel.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.funnels]
        arr.push(payload.funnel)
        state.funnels = arr
      }),
      builder.addCase(createFunnel.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(queryFunnels.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(queryFunnels.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.funnels = payload.results
      }),
      builder.addCase(queryFunnels.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(updateFunnel.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(updateFunnel.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.funnels]
        const idx = state.funnels.findIndex((funnel: IFunnel) => funnel.id === payload.funnel.id)
        arr[idx] = payload.funnel
        state.funnels = arr
      }),
      builder.addCase(updateFunnel.rejected, (state: any) => {
        state.loading = false
      })

      builder.addCase(deleteFunnel.pending, (state: any) => {
        state.loading = true
      }),
        builder.addCase(deleteFunnel.fulfilled, (state: any, { payload }) => {
          state.loading = false
          const arr = [...state.funnels]
          const idx = state.funnels.findIndex((funnel: IFunnel) => funnel.id === payload.id)
          arr.splice(idx, 1)
          state.funnels = arr
        }),
        builder.addCase(deleteFunnel.rejected, (state: any) => {
          state.loading = false
        })
  },
})

export const funnelActions = FunnelSlice.actions

export default FunnelSlice
