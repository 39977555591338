import { createNotification, deleteNotification, queryNotificationsNoRead, updateNotification } from './../actions/notification';
import { createSlice } from '@reduxjs/toolkit'
import { INotification } from '../../types/notification'
import { queryNotifications } from '../actions/notification'

interface InitialState {
  loading: boolean
  notifications: INotification[]
  totalPages: number
  totalResults: number
  unRead: number
}

const initialState: InitialState = {
  loading: false,
  notifications: [],
  totalPages: 0,
  totalResults: 0,
  unRead: 0
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNotification.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(createNotification.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.notifications]
        arr.push(payload.notification)
        state.notifications = arr
      }),
      builder.addCase(createNotification.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(queryNotifications.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(queryNotifications.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.notifications = payload.results
        state.totalPages = payload.totalPages
        state.totalResults = payload.totalResults
      }),
      builder.addCase(queryNotifications.rejected, (state: any) => {
        state.loading = false
      }),


      builder.addCase(queryNotificationsNoRead.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(queryNotificationsNoRead.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.unRead = payload.totalResults
      }),
      builder.addCase(queryNotificationsNoRead.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(updateNotification.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(updateNotification.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.notifications]
        const idx = state.notifications.findIndex((notification: INotification) => notification.id === payload.notification.id)
        arr[idx] = payload.notification
        state.notifications = arr
        state.unRead = state.unRead - 1
      }),
      builder.addCase(updateNotification.rejected, (state: any) => {
        state.loading = false
      })

      builder.addCase(deleteNotification.pending, (state: any) => {
        state.loading = true
      }),
        builder.addCase(deleteNotification.fulfilled, (state: any, { payload }) => {
          state.loading = false
          const arr = [...state.notifications]
          const idx = state.notifications.findIndex((notification: INotification) => notification.id === payload.id)
          arr.splice(idx, 1)
          state.notifications = arr
        }),
        builder.addCase(deleteNotification.rejected, (state: any) => {
          state.loading = false
        })
  },
})

export const notificationActions = NotificationSlice.actions

export default NotificationSlice
