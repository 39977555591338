import { FaWhatsapp } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { IContact } from '../../../../types/contact'
import { formatPhone } from '../../../../utils/format'
import NoData from '../../../../../public/assets/no-data.svg'

type ContactsTableProps = {
  contacts: IContact[]
}

const baseWppLink = 'https://web.whatsapp.com/send/?phone='

function formatNumberToWpp(contact: IContact) {
  return `${contact.countryCode}${contact.mobileNumber}`.replace('+', '')
}

export default function ContactsTable({ contacts }: ContactsTableProps) {
  const navigate = useNavigate()
  return (
    <div>
      <table className="w-full table-auto">
        <thead>
          <tr className="border-b text-left">
            <th></th>
            <th>Nome</th>
            <th>Celular</th>
            <th>Email</th>
            <th>Funil</th>
            <th>Responsável</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map(contact => (
            <tr key={contact.id} className="hover:bg-gray-100">
              <td className="py-4 text-sm">
                <div>
                  <img src={contact.avatar} className="h-12 w-12 rounded-md" />
                </div>
              </td>
              <td className="py-4 text-sm">
                <div onClick={() => navigate(`/contatos/v/${contact.id}`)}>
                  <span className="cursor-pointer font-bold">
                    {contact.name}
                  </span>
                </div>
              </td>
              <td className="py-4 text-sm">
                <div>
                  <span>{formatPhone(contact)}</span>
                </div>
              </td>
              <td className="py-4 text-sm">
                <div>
                  <span>{contact.email}</span>
                </div>
              </td>
              <td>
                <span
                  className="rounded-full p-1 px-2 text-sm font-semibold text-white"
                  style={{ backgroundColor: contact.funnel?.color }}
                >
                  {contact.funnel?.name}
                </span>
              </td>
              <td>
                <span>{contact?.responsible?.name}</span>
              </td>
              <td className="py-4 text-sm">
                <div className="flex">
                  <div
                    onClick={() => {
                      window.open(
                        baseWppLink +
                          formatNumberToWpp(contact) +
                          '&text&app_absent=0',
                        '_blank',
                        'noreferrer'
                      )
                    }}
                    className="cursor-pointer rounded-full bg-gray-200 p-2 hover:bg-gray-300"
                  >
                    <FaWhatsapp />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!contacts.length && (
          <div className='flex-col items-center py-10 w-full flex justify-center'>
            <img className='w-40' src={NoData} />
            <span className='mt-4 font-bold text-lg'>Nenhum contato encontrado.</span>
          </div>
        )}
    </div>
  )
}
