import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../../../store'
import { alertActions } from '../../../../store/slices/alert'
import { Button, Modal, TextArea, TextField } from '../../../ui'
import { createReply } from '../../../../store/actions/reply'

type NewReplyModalProps = {
  isOpen: boolean
  onClose(): void
}

export default function NewReplyModal({ isOpen, onClose }: NewReplyModalProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.auth)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  async function onSubmit(values: any) {
    Object.assign(values, { companyId: user?.companyId })
    const response = await dispatch(createReply(values))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Resposta criado com sucesso.',
        })
      )
      onClose()
      reset()
    } else {
      dispatch(
        alertActions.createAlert({
          type: 'danger',
          title: 'Ops...',
          message: 'Resposta ocorreu algum erro.',
        })
      )
    }
  }

  const colorBase =
    'flex items-center justify-center ml-2 cursor-pointer hover:border-2 hover:shadow-md hover:border-white w-6 h-6 rounded-full bg-gray-400'

  return (
    <Modal title="Nova Resposta" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="name"
          label="Nome"
          register={register}
          error={errors?.name && 'Informe o nome'}
        />
        <TextArea
          name="message"
          label="Mensagem"
          register={register}
          error={errors?.message && 'Informe o mensagem'}
        />

        <div className="mt-4">
          <Button type="submit" variant="primary">
            Adicionar
          </Button>
        </div>
      </form>
    </Modal>
  )
}
