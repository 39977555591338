import { FaWhatsapp } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { IReply } from '../../../../types/reply'

type RepliesTableProps = {
  replies: IReply[]
}

export default function RepliesTable({ replies }: RepliesTableProps) {
  const navigate = useNavigate()
  return (
    <div>
      <table className="w-full table-auto">
        <thead>
          <tr className="border-b text-left">
            <th>Nome</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {replies.map(reply => (
            <tr key={reply.id} className="hover:bg-gray-100">
              <td className="py-4 text-sm">
                <div onClick={() => navigate(`/respostas/v/${reply.id}`)}>
                  <span className="cursor-pointer font-bold">
                    {reply.name}
                  </span>
                </div>
              </td>
              <td className="py-4 text-sm">
                <div className="flex"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
