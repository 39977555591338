import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'
import { FaCheck } from 'react-icons/fa'
import { Container, DeleteModal, TopBar } from '../../components/common'
import Main from '../../components/common/Main'
import { Button, ProfileSkeleton, TextField } from '../../components/ui'
import { IFunnel } from '../../types/funnel'
import api from '../../utils/api'
import { useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { deleteFunnel, updateFunnel } from '../../store/actions/funnel'
import { useSelector } from 'react-redux'
import { funnelColors } from '../../utils/colors'
import { alertActions } from '../../store/slices/alert'

const colorBase =
  'flex items-center justify-center ml-2 cursor-pointer hover:border-2 hover:shadow-md hover:border-white w-6 h-6 rounded-full bg-gray-400'

export default function FunnelViewPage() {
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const auth = useSelector((state: RootState) => state.auth)
  const [funnel, setFunnel] = useState<IFunnel | null>(null)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [selectedColor, setSelectedColor] = useState<string>('#475569')

  async function fetchFunnel() {
    const response = await api.get(`/funnels/${id}`)
    setFunnel(response.data.funnel)
  }

  async function handleDelete() {
    const response = await dispatch(deleteFunnel(funnel?.id))
    if (response.meta.requestStatus === 'fulfilled') {
      navigate('/funil')
    }
  }

  async function onSubmit(values: any) {
    const input = Object.assign({}, funnel, values, { color: selectedColor })
    const response = await dispatch(updateFunnel(input))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Funil atualizado',
        })
      )
    } else {
      dispatch(
        alertActions.createAlert({
          type: 'danger',
          title: 'Ops!',
          message: 'Ocorreu um erro inesperado',
        })
      )
    }
  }

  useEffect(() => {
    fetchFunnel()
  }, [])

  useEffect(() => {
    if (funnel) {
      setValue('name', funnel.name)
      setSelectedColor(funnel.color)
    }
  }, [funnel])

  return (
    <>
      <TopBar title="Detalhes do Usuário" />
      <Main>
        <Container>
          <div className="max-w-md">
            {funnel ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  name="name"
                  label="Nome"
                  required
                  error={errors?.name && 'Informe o nome'}
                  register={register}
                />
                <div className="my-4 flex">
                  {funnelColors.map(color => (
                    <div
                      onClick={() => setSelectedColor(color.color)}
                      key={color.color}
                      style={{ backgroundColor: color.color }}
                      className={colorBase}
                    >
                      {selectedColor === color.color && (
                        <FaCheck color="#fff" />
                      )}
                    </div>
                  ))}
                </div>
                <div className="mt-6 flex items-center justify-between">
                  <Button variant="primary">Salvar</Button>
                  <button
                    onClick={() => setDeleteModal(true)}
                    type="button"
                    className="rounded-md bg-red-100 p-2 text-red-500 hover:text-red-600"
                  >
                    <MdDelete size={20} />
                  </button>
                </div>
              </form>
            ) : (
              <ProfileSkeleton />
            )}
          </div>
        </Container>
      </Main>
      <DeleteModal
        onSubmit={handleDelete}
        description="A exclusão desse usuário é irreversivel"
        title="Deseja excluir esse usuário?"
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </>
  )
}
