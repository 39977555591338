import React, { useEffect, useState } from 'react'
import {
  TopBar,
  Main,
  MenuItem,
  Container,
  Pagination,
} from '../../components/common'
import cls from 'classnames'
import { MdOutlineScheduleSend } from 'react-icons/md'
import { INotification } from '../../types/notification'
import { AppDispatch, RootState } from '../../store'
import { useDispatch } from 'react-redux'
import {
  queryNotifications,
  updateNotification,
} from '../../store/actions/notification'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const notBase =
  'flex justify-between w-full cursor-pointer items-center border bg-white p-4 mb-2'

function renderIcon(type: string) {
  switch (type) {
    default:
      return <MdOutlineScheduleSend />
  }
}

export default function NotificationsPage() {
  const limit = 10
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState<string>('all')
  const dispatch: AppDispatch = useDispatch()
  const { notifications, totalPages, totalResults } = useSelector(
    (state: RootState) => state.notification
  )

  async function handleRead(notification: INotification) {
    const input = Object.assign({}, notification, { isRead: true })
    await dispatch(updateNotification(input))
  }

  async function handleOpen(notification: INotification) {
    handleRead(notification)
    if (notification.link) {
      navigate(notification.link)
    }
  }

  useEffect(() => {
    const params: any = {
      page
    }
    if (filter === 'unread') params.isRead = false
    dispatch(queryNotifications(params))
  }, [filter])

  return (
    <>
      <TopBar title="Notificações" />
      <Main>
        <div className="flex h-full">
          <div className="h-full w-52 border-r px-2 py-4">
            <div className="">
              <MenuItem
                onClick={() => setFilter('all')}
                active={filter === 'all'}
                label="Todas"
              />
              <MenuItem
                onClick={() => setFilter('unread')}
                label="Não lidas"
                active={filter === 'unread'}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="w-full p-4">
              <div className="w-full">
                {notifications.map((notification: INotification) => (
                  <div onClick={() => handleOpen(notification)} key={notification.id} className={notBase}>
                    <div className="flex">
                      <div
                        className={cls(
                          'mr-4 flex h-14 w-14 items-center justify-center rounded-full text-2xl',
                          !notification.isRead
                            ? 'bg-indigo-100 text-indigo-600'
                            : 'bg-gray-100 text-gray-400'
                        )}
                      >
                        {renderIcon(notification.type)}
                      </div>
                      <div>
                        <h4 className="text-md font-semibold">
                          Notification title
                        </h4>
                        <p>Notificationd escription her</p>
                        <p className="mt-2 text-xs">14 minutos atras</p>
                      </div>
                    </div>
                    {!notification.isRead && (
                      <div>
                        <span
                          onClick={() => handleRead(notification)}
                          className="text-xs font-semibold text-orange-400 hover:text-orange-500"
                        >
                          Marcar como Lida
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <Pagination
                limit={limit}
                page={page}
                setCurrentPage={(n: number) => setPage(n)}
                totalPages={totalPages}
                totalResults={totalResults}
              />
            </div>
          </div>
        </div>
      </Main>
    </>
  )
}
