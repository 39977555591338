import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Container } from '../../components/common'
import { Button, TextField } from '../../components/ui'
import { AppDispatch } from '../../store'
import { alertActions } from '../../store/slices/alert'
import { ICompany } from '../../types/company'
import api from '../../utils/api'

export default function SettingsPage() {
  const dispatch: AppDispatch = useDispatch()
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [company, setCompany] = useState<ICompany | null>(null)

  async function fetchCompany() {
    const response = await api.get('/companies')
    setCompany(response.data.company)
  }

  async function onSubmit(values: any) {
    const input = Object.assign({}, company, values)
    const response = await api.put(`/companies/${company?.id}`, input)
    if (response.status === 200) {
      setCompany(response.data.company)
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Empresa atualizada.',
        })
      )
    }
  }

  useEffect(() => {
    fetchCompany()
  }, [])

  useEffect(() => {
    if (company) {
      setValue('name', company.name)
    }
  }, [company])

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="name"
          label="Nome"
          register={register}
          required
          error={errors?.name && 'Informe o nome'}
        />
        <div className="mt-6">
          <Button variant="primary">Salvar</Button>
        </div>
      </form>
    </Container>
  )
}
