import { createSlice } from "@reduxjs/toolkit";
import { IAlert } from "../../types/alert";

type InitialState = {
  alerts: IAlert[]
}

const initialState: InitialState = {
  alerts: []
}

export const AlertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    createAlert: (state, action) => {
      state.alerts.push({
        title: action.payload.title,
        message: action.payload.message,
        type: action.payload.type
      });
    }
  },
});

export const alertActions = AlertSlice.actions;

export default AlertSlice;
