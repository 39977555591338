import React from 'react'

export default function SimpleSkeleton() {
  return (
    <div className="flex flex-col animate-pulse">
      <h3
        className="h-4 rounded-md bg-gray-200 dark:bg-gray-200"
        style={{ width: '40%' }}
      ></h3>

      <ul className="mt-5 space-y-3">
        <li className="h-4 w-full rounded-md bg-gray-200 dark:bg-gray-200"></li>
        <li className="h-4 w-full rounded-md bg-gray-200 dark:bg-gray-200"></li>
        <li className="h-4 w-full rounded-md bg-gray-200 dark:bg-gray-200"></li>
        <li className="h-4 w-full rounded-md bg-gray-200 dark:bg-gray-200"></li>
      </ul>
    </div>
  )
}
