import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, TextField } from '../../components/ui'
import { AppDispatch, RootState } from '../../store'
import { authLogin } from '../../store/actions/auth'
import { alertActions } from '../../store/slices/alert'

export default function LoginPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { loading } = useSelector((state: RootState) => state.auth)
  const { register, handleSubmit, formState: { errors } } = useForm()

  async function onSubmit(values: any) {
    const response = await dispatch(authLogin(values))
    if (response.meta.requestStatus === "fulfilled") {
      await dispatch(alertActions.createAlert({
        type: "success",
        title: "Sucesso!",
        message: "Login realizado."
      }))

      setTimeout(() => {
        navigate("/")
      }, 2000);
    } else {
      await dispatch(alertActions.createAlert({
        type: "danger",
        title: "Ops...",
        message: "Email ou Senha incorreto."
      }))
    }
  }

  return (
    <div className='flex items-center justify-center min-h-screen'>
      <div className='max-w-xs w-full'>
        <div>
          <h1>Bem vindo de volta!</h1>
          <p>Informe suas credenciais para acessar o sistema.</p>
        </div>
        <form className='border-b py-10' onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="email"
            label="Email"
            register={register}
            required
            error={errors.email && "Informe o email"}
          />
          <TextField
            type="password"
            name="password"
            label="Senha"
            required
            register={register}
            error={errors.password && "Informe a senha"}
          />
          <div className='mt-6'>
            <Button loading={loading} variant='primary'>Entrar</Button>
          </div>
        </form>
        {/* <div className='mt-10'>
          <div className='flex items-center justify-between'>
            <p>Não tem uma conta?</p>
            <a>Criar conta</a>
          </div>
        </div> */}
      </div>
    </div>
  )
}
