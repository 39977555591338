import React, { useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'
import { IFunnel } from '../../../../types/funnel'
import { Button, SelectTag, SideModal, TextField } from '../../../ui'

type Props = {
  isOpen: boolean
  onClose(): void
  funnels: IFunnel[]
  setFilter(value: any): void
}

export default function ContactsFilterModal({ setFilter, isOpen, onClose, funnels }: Props) {
  const { register, control, watch, reset } = useForm()

  const name = watch("name")
  const funnel = watch("funnel")

  useEffect(() => {
    console.log("funnel", funnel)
    setFilter({
      name,
      funnel
    })
  }, [name, funnel])

  return (
    <SideModal isOpen={isOpen} onClose={onClose} title="Filtros">
      <form>
        <TextField name="name" register={register} label="Nome" />
        <SelectTag
          name="funnel"
          label="Funil"
          control={control}
          options={funnels.map(funnel => ({
            label: funnel.name,
            value: funnel.id,
            color: funnel.color,
          }))}
        />
        <div className='mt-6'>
          <Button onClick={() => reset()} type="button" variant='danger'>
            Limpar Filtro
          </Button>
        </div>
      </form>
    </SideModal>
  )
}
