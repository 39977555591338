import { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  Container,
  TopBar,
  RepliesTable,
  NewReplyModal,
} from '../../components/common'
import Main from '../../components/common/Main'
import { Button } from '../../components/ui'
import { AppDispatch, RootState } from '../../store'
import { queryReplies } from '../../store/actions/reply'

export default function RepliesPage() {
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState<boolean>(false)
  const { replies } = useSelector((state: RootState) => state.reply)

  useEffect(() => {
    dispatch(queryReplies({}))
  }, [])

  return (
    <div>
      <TopBar title="Respostas" />
      <Main>
        <Container>
          <div className="my-6 flex items-center justify-end">
            <Button
              onClick={() => setOpen(true)}
              icon={<FiPlus />}
              variant="primary"
            >
              Add Resposta
            </Button>
          </div>

          <RepliesTable replies={replies} />
        </Container>
      </Main>
      <NewReplyModal isOpen={open} onClose={() => setOpen(false)} />
    </div>
  )
}
