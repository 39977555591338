export const funnelColors = [
  { color: "#475569", background: "#f1f5f9"},
  { color: "#ea580c", background: "#ffedd5"},
  { color: "#db2777", background: "#fce7f3"},
  { color: "#4338ca", background: "#e0e7ff"},
  { color: "#1e40af", background: "#dbeafe"},
  { color: "#0ea5e9", background: "#e0f2fe"},
  { color: "#14b8a6", background: "#cffafe"},
  { color: "#65a30d", background: "#ecfccb"},
  { color: "#eab308", background: "#fef9c3"},
  { color: "#d97706", background: "#fef3c7"}
]
