import React from 'react'
import { Button, Modal } from '../../ui'

type Props = {
  isOpen: boolean
  onClose(): void
  title: string
  description: string
  onSubmit(): void
}

export default function DeleteModal({
  isOpen,
  onClose,
  onSubmit,
  title,
  description
}: Props) {
  return (
    <Modal title={title} onClose={onClose} isOpen={isOpen}>
      <div>
        <p>{description}</p>
      </div>
      <div className='mt-4 flex items-center space-x-2'>
        <Button onClick={onSubmit} variant='danger'>Deletar</Button>
        <Button onClick={onClose}>Cancelar</Button>
      </div>

    </Modal>
  )
}
