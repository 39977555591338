import { createReply, deleteReply, updateReply } from './../actions/reply';
import { createSlice } from '@reduxjs/toolkit'
import { IReply } from '../../types/reply'
import { queryReplies } from '../actions/reply'

interface InitialState {
  loading: boolean
  replies: IReply[]
}

const initialState: InitialState = {
  loading: false,
  replies: []
}

export const ReplySlice = createSlice({
  name: 'reply',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createReply.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(createReply.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.replies]
        arr.push(payload.reply)
        state.replies = arr
      }),
      builder.addCase(createReply.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(queryReplies.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(queryReplies.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.replies = payload.results
      }),
      builder.addCase(queryReplies.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(updateReply.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(updateReply.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.replies]
        const idx = state.replies.findIndex((reply: IReply) => reply.id === payload.reply.id)
        arr[idx] = payload.reply
        state.replies = arr
      }),
      builder.addCase(updateReply.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(deleteReply.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(deleteReply.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.replies]
        const idx = state.replies.findIndex((reply: IReply) => reply.id === payload.id)
        arr.splice(idx, 1)
        state.replies = arr
      }),
      builder.addCase(deleteReply.rejected, (state: any) => {
        state.loading = false
      })
  },
})

export const replyActions = ReplySlice.actions

export default ReplySlice
