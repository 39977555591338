import { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  Container,
  TopBar,
  NewContactModal,
  FunnelsTable,
  NewFunnelModal,
} from '../../components/common'
import Main from '../../components/common/Main'
import { Button } from '../../components/ui'
import { AppDispatch, RootState } from '../../store'
import { queryFunnels } from '../../store/actions/funnel'

export default function FunnelsPage() {
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState<boolean>(false)
  const { funnels } = useSelector((state: RootState) => state.funnel)

  useEffect(() => {
    dispatch(queryFunnels({}))
  }, [])

  return (
    <div>
      <TopBar title="Funil" />
      <Main>
        <Container>
          <div className="my-6 flex items-center justify-end">
            <Button
              onClick={() => setOpen(true)}
              icon={<FiPlus />}
              variant="primary"
            >
              Add Funil
            </Button>
          </div>

          <FunnelsTable funnels={funnels} />
        </Container>
      </Main>
      <NewFunnelModal isOpen={open} onClose={() => setOpen(false)} />
    </div>
  )
}
