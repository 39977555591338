import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { IReminder } from '../../../../types/reminder'
import NoData from '../../../../../public/assets/no-data.svg'

type RemindersTableProps = {
  reminders: IReminder[]
}

export default function RemindersTable({ reminders }: RemindersTableProps) {
  const navigate = useNavigate()
  return (
    <div>
      <table className="w-full table-auto">
        <thead>
          <tr className="border-b text-left">
            <th>Titulo</th>
            <th>Contato</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {reminders.map(reminder => (
            <tr key={reminder.id} className="hover:bg-gray-100">
              <td className="py-4 text-sm">
                <div onClick={() => navigate(`/lembretes/v/${reminder.id}`)}>
                  <span className="cursor-pointer font-bold">
                    {reminder.title}
                  </span>
                </div>
              </td>
              <td className="py-4 text-sm">
                <div className="flex">
                  <div>
                    <img src={reminder.contactId?.avatar} className="h-10 w-10 rounded-md" />
                  </div>
                  <div className='ml-2'>
                    <h4 className='font-semibold text-md'>{reminder.contactId?.name}</h4>
                    <p>{reminder.contactId?.mobileNumber}</p>
                  </div>
                </div>
              </td>
              <td className="py-4 text-sm">
                <div className="flex">
                  {dayjs(reminder.remindAt).format("DD/MM/YYYY HH:mm")}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!reminders.length && (
        <div className="flex w-full flex-col items-center justify-center py-10">
          <img className="w-40" src={NoData} />
          <span className="mt-4 text-lg font-bold">
            Nenhum lembrete encontrado.
          </span>
        </div>
      )}
    </div>
  )
}
