import React, { useEffect, useRef, useState } from 'react'
import { MdLogout, MdOutlineSettings, MdOutlineDarkMode } from 'react-icons/md'
import { CgProfile } from 'react-icons/cg'
import cls from 'classnames'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { IUser } from '../../../types/user'
import { authActions } from '../../../store/slices/auth'
import { AppDispatch } from '../../../store'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

type ProfileDropdownProps = {
  user?: IUser
}

export default function ProfileDropdown({ user }: ProfileDropdownProps) {
  const ref = useRef()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)

  useOnClickOutside(ref, () => setOpen(false))

  async function handleLogout() {
    await dispatch(authActions.signout())
  }

  useEffect(() => {
    document.documentElement.classList.add('dark')
  }, [])

  return (
    <div className="relative">
      <div
        ref={ref as any}
        className={cls(
          'bottom-16 w-44 rounded-md border p-2',
          open ? 'absolute' : 'hidden'
        )}
      >
        <div
          onClick={() => {
            navigate('/meu-perfil')
            setOpen(false)
          }}
          className="flex cursor-pointer items-center rounded-md p-2 text-xs hover:bg-gray-100"
        >
          <span>
            <CgProfile size={14} />
          </span>
          <span className="ml-2">Meu Perfil</span>
        </div>

        <div
          onClick={() => {
            navigate('/configuracoes/empresa')
            setOpen(false)
          }}
          className="flex cursor-pointer items-center rounded-md p-2 text-xs hover:bg-gray-100"
        >
          <span>
            <MdOutlineSettings size={14} />
          </span>
          <span className="ml-2">Configurações</span>
        </div>

        {/* <div className="flex cursor-pointer items-center rounded-md p-2 text-xs hover:bg-gray-100">
          <span>
            <MdOutlineDarkMode size={14} />
          </span>
          <span className="ml-2">Modo Noturno</span>
        </div> */}

        <div
          onClick={handleLogout}
          className="flex cursor-pointer items-center rounded-md p-2 text-xs hover:bg-gray-100"
        >
          <span>
            <MdLogout size={14} />
          </span>
          <span className="ml-2">Sair</span>
        </div>
      </div>

      <div
        onClick={() => setOpen(true)}
        className={cls(
          'flex cursor-pointer items-center rounded-md p-4 hover:bg-gray-100',
          open && 'bg-gray-100'
        )}
      >
        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-white">
          <span className="text-xs">LC</span>
        </div>
        <span className="ml-2 text-sm font-bold">{user?.name}</span>
      </div>
    </div>
  )
}
