import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const authLogin = createAsyncThunk(
  'auth/login',
  async (loginInput: any, { rejectWithValue }: any) => {
    try {
      const response = await api.post('/auth/login', loginInput)
      const { accessToken } = response.data
      api.defaults.headers = {
        Authorization: `Bearer ${accessToken}`
      } as any
      localStorage.setItem('@app/token',accessToken)
      localStorage.setItem('@app/companyId', response.data.user.companyId)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)


export const getMe = createAsyncThunk(
  'auth/me',
  async (_, { rejectWithValue }: any) => {
    try {
      const response = await api.get('/auth/me')
      localStorage.setItem('@app/companyId', response.data.user.companyId)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const updateMe = createAsyncThunk(
  'auth/update',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.put(`/users/${input.id}`, input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
