import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { FaCheck } from 'react-icons/fa'
import { AppDispatch, RootState } from '../../../../store'
import { alertActions } from '../../../../store/slices/alert'
import { Button, Modal, SearchContact, TextArea, TextField } from '../../../ui'
import { funnelColors } from '../../../../utils/colors'
import { createReminder } from '../../../../store/actions/reminder'
import { IContact } from '../../../../types/contact'
import api from '../../../../utils/api'

type NewReminderModalProps = {
  isOpen: boolean
  onClose(): void
}

export default function NewReminderModal({
  isOpen,
  onClose,
}: NewReminderModalProps) {
  const [contacts, setContacts] = useState<IContact[]>([])
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.auth)
  const {
    control,
    register,
    handleSubmit,
    resetField,
    reset,
    formState: { errors },
  } = useForm()

  async function onSubmit(values: any) {
    setContacts([])
    Object.assign(values, { companyId: user?.companyId })
    const response = await dispatch(createReminder(values))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Lembrete criado com sucesso.',
        })
      )
      onClose()
      reset()
    } else {
      dispatch(
        alertActions.createAlert({
          type: 'danger',
          title: 'Ops...',
          message: 'Lembrete ocorreu algum erro.',
        })
      )
    }
  }

  async function onSearch(value: string) {
    if (value.length >= 3) {
      const params = {
        name: value,
        limit: 999,
      }
      const response = await api.get('/contacts', { params })
      setContacts(response.data.results)
    }
  }

  return (
    <Modal title="Novo Lembrete" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SearchContact
          options={contacts.map(option => ({
            label: option.name,
            value: option,
          }))}
          onSearch={onSearch}
          placeholder="Procurar por nome..."
          name="contactId"
          label="Contato"
          control={control}
          required
          error={errors?.contactId && 'Informe o contato'}
        />
        <TextField
          name="title"
          label="Titulo"
          register={register}
          required
          error={errors?.name && 'Informe o nome'}
        />
        <TextArea
          name="description"
          label="Descrição"
          register={register}
          required
          error={errors?.name && 'Informe o nome'}
        />
        <TextField
          type="datetime-local"
          name="remindAt"
          label="Lembrar em"
          register={register}
          required
          error={errors?.remindAt && 'Informe a data'}
        />
        <div className="mt-4">
          <Button type="submit" variant="primary">
            Adicionar
          </Button>
        </div>
      </form>
    </Modal>
  )
}
