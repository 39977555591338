import { createUser, deleteUser, updateUser } from './../actions/user';
import { createSlice } from '@reduxjs/toolkit'
import { IUser } from '../../types/user'
import { queryUsers } from '../actions/user'

interface InitialState {
  loading: boolean
  users: IUser[]
}

const initialState: InitialState = {
  loading: false,
  users: []
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(createUser.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.users]
        arr.push(payload.user)
        state.users = arr
      }),
      builder.addCase(createUser.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(queryUsers.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(queryUsers.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.users = payload.results
      }),
      builder.addCase(queryUsers.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(updateUser.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(updateUser.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.users]
        const idx = state.users.findIndex((user: IUser) => user.id === payload.user.id)
        arr[idx] = payload.user
        state.users = arr
      }),
      builder.addCase(updateUser.rejected, (state: any) => {
        state.loading = false
      })

    builder.addCase(deleteUser.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(deleteUser.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.users]
        const idx = state.users.findIndex((user: IUser) => user.id === payload.id)
        arr.splice(idx, 1)
        state.users = arr
      }),
      builder.addCase(deleteUser.rejected, (state: any) => {
        state.loading = false
      })
  },
})

export const userActions = UserSlice.actions

export default UserSlice
