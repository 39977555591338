import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const createUser = createAsyncThunk(
  'user/create',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.post('/users', input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const queryUsers = createAsyncThunk(
  'user/query',
  async (params: any, { rejectWithValue }: any) => {
    try {
      const response = await api.get('/users', { params })
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const updateUser = createAsyncThunk(
  'user/update',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.put(`/users/${input.id}`, input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (id: any, { rejectWithValue }: any) => {
    try {
      await api.delete(`/users/${id}`)
      return { id }
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
