import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { MdDelete } from 'react-icons/md'
import { DeleteModal, TopBar } from '../../components/common'
import {
  Button,
  SimpleSkeleton,
  TextArea,
  TextField,
} from '../../components/ui'
import { IReminder } from '../../types/reminder'
import api from '../../utils/api'
import { deleteReminder, updateReminder } from '../../store/actions/reminder'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { alertActions } from '../../store/slices/alert'
import Main from '../../components/common/Main'

export default function ReminderViewPage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [reminder, setReminder] = useState<IReminder | null>(null)
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm()

  async function fetchReminder() {
    const response = await api.get(`/reminders/${id}`)
    setReminder(response.data.reminder)
  }

  useEffect(() => {
    fetchReminder()
  }, [])

  useEffect(() => {
    if (reminder) {
      setValue('title', reminder.title)
      setValue('description', reminder.description)
      setValue('remindAt', dayjs(reminder.remindAt).format('DD/MM/YYYY HH:mm'))
    }
  }, [reminder])

  async function onSubmit(values: any) {
    const input = Object.assign({}, reminder, values)
    const response = await dispatch(updateReminder(input))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Lembrete atualizado.',
        })
      )
    }
  }

  async function handleDelete() {
    const response = await dispatch(deleteReminder(reminder?.id))
    if (response.meta.requestStatus === 'fulfilled') {
      navigate('/lembretes')
    }
  }

  return (
    <>
      <TopBar title="Detalhes do Lembrete" />
      <Main>
        <div className="max-w-md p-6">
          {reminder ? (
            <>
              <div>
                <h4>Contato</h4>
                <div className="flex">
                  <img
                    src={reminder.contactId?.avatar}
                    className="h-16 w-16 rounded-md bg-gray-300"
                  />
                  <div className="ml-4">
                    <h4 className="text-lg font-bold">
                      {reminder.contactId?.name}
                    </h4>
                    <h4>{reminder.contactId?.mobileNumber}</h4>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
                <TextField
                  name="title"
                  label="Titulo"
                  register={register}
                  required
                  error={errors?.title && 'Informe o titulo'}
                />
                <TextArea
                  name="description"
                  label="Descrição"
                  register={register}
                  required
                  error={errors?.description && 'Informe o descrição'}
                />
                <TextField
                  type="datetime-local"
                  name="remindAt"
                  label="Data"
                  register={register}
                  required
                  error={errors?.remindAt && 'Informe a data'}
                />
                <div className="mt-6 flex items-center justify-between">
                  <Button variant="primary">Salvar</Button>
                  <button
                    onClick={() => setDeleteModal(true)}
                    type="button"
                    className="rounded-md bg-red-100 p-2 text-red-500 hover:text-red-600"
                  >
                    <MdDelete size={20} />
                  </button>
                </div>
              </form>
              <DeleteModal
                onSubmit={handleDelete}
                description="A exclusão desse lembrete é irreversivel"
                title="Deseja excluir esse lembrete?"
                isOpen={deleteModal}
                onClose={() => setDeleteModal(false)}
              />
            </>
          ) : (
            <SimpleSkeleton />
          )}
        </div>
      </Main>
    </>
  )
}
