import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, TopBar } from '../../components/common'
import Main from '../../components/common/Main'
import { Button, TextField } from '../../components/ui'
import { AppDispatch, RootState } from '../../store'
import { updateMe } from '../../store/actions/auth'
import { alertActions } from '../../store/slices/alert'

export default function NewPassword() {
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { user } = useSelector((state: RootState) => state.auth)

  async function onSubmit(values: any) {
    const input = Object.assign({}, user, values, { updatedPassword: true })
    const response = await dispatch(updateMe(input))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Senha atualizada com sucesso.',
        })
      )
      navigate('/')
    }
  }

  return (
    <div>
      <TopBar title="Nova Senha" />
      <Main>
        <Container>
          <div className="w-full max-w-md">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                type="password"
                name="password"
                label="Nova Senha"
                register={register}
                required
                error={errors?.password && 'Informe uma nova senha'}
              />
              {/* <TextField
              type='password'
              name='confirmPassword'
              label='Confirme a Senha'
              register={register}
              required
              error={errors?.password && "Confirme a nova senha"}
            /> */}
              <div className="mt-6">
                <Button variant="primary">Atualizar senha</Button>
              </div>
            </form>
          </div>
        </Container>
      </Main>
    </div>
  )
}
