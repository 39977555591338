import { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  Container,
  TopBar,
  RemindersTable,
  NewReminderModal,
} from '../../components/common'
import Main from '../../components/common/Main'
import { Button } from '../../components/ui'
import { AppDispatch, RootState } from '../../store'
import { queryReminders } from '../../store/actions/reminder'

export default function RemindersPage() {
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState<boolean>(false)
  const { reminders } = useSelector((state: RootState) => state.reminder)

  useEffect(() => {
    dispatch(queryReminders({}))
  }, [])

  return (
    <div>
      <TopBar title="Lembretes" />
      <Main>
        <Container>
          <div className="my-6 flex items-center justify-end">
            <Button
              onClick={() => setOpen(true)}
              icon={<FiPlus />}
              variant="primary"
            >
              Add Lembrete
            </Button>
          </div>

          <RemindersTable reminders={reminders} />
        </Container>
      </Main>
      <NewReminderModal isOpen={open} onClose={() => setOpen(false)} />
    </div>
  )
}
