import { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'
import { RiFilter3Fill } from 'react-icons/ri'
import {
  ContactsTable,
  Container,
  TopBar,
  NewContactModal,
  Pagination,
  ContactsFilterModal,
} from '../../components/common'
import Main from '../../components/common/Main'
import { Button, ButtonIcon } from '../../components/ui'
import { AppDispatch, RootState } from '../../store'
import { queryContacts } from '../../store/actions/contact'
import { IFunnel } from '../../types/funnel'
import api from '../../utils/api'

export default function ContactsPage() {
  const [funnels, setFunnels] = useState<IFunnel[]>([])
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState<boolean>(false)
  const [filterModal, setFilterModal] = useState<boolean>(false)
  const [filter, setFilter] = useState({
    name: '',
    funnel: '',
  })
  const { contacts, totalPages, totalResults } = useSelector(
    (state: RootState) => state.contact
  )
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  async function fetchFunnel() {
    const response = await api.get('/funnels', { params: { limit: 999 } })
    setFunnels(response.data.results)
  }

  useEffect(() => {
    fetchFunnel()
  }, [])

  useEffect(() => {
    dispatch(
      queryContacts({
        page,
        limit,
        ...filter,
      })
    )
  }, [page, filter])

  return (
    <>
      <TopBar title="Contatos" />
      <Main>
        <Container>
          <div className="my-6 flex items-center justify-end space-x-2">
            <ButtonIcon onClick={() => setFilterModal(true)} icon={<RiFilter3Fill />} />
            <Button
              onClick={() => setOpen(true)}
              icon={<FiPlus />}
              variant="primary"
            >
              Add Contato
            </Button>
          </div>

          <ContactsTable contacts={contacts} />
          <Pagination
            limit={limit}
            page={page}
            setCurrentPage={(n: number) => setPage(n)}
            totalPages={totalPages}
            totalResults={totalResults}
          />
        </Container>
      </Main>
      <ContactsFilterModal
        setFilter={(value: any) => setFilter(value)}
        funnels={funnels}
        isOpen={filterModal}
        onClose={() => setFilterModal(false)}
      />
      <NewContactModal isOpen={open} onClose={() => setOpen(false)} />
    </>
  )
}
