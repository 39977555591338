import React, { ReactNode } from 'react'
import cls from 'classnames'

type ButtonProps = {
  children: string
  variant?: string
  icon?: ReactNode
  onClick?(): void
  loading?: boolean
  type?: any
}

const base =
  'flex items-center text-sm transition ease-in-out delay-75 px-3 py-2 rounded font-medium'

const variants: any = {
  default: 'bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100',
  primary: 'bg-blue-500 hover:bg-blue-600 disabled:bg-blue-100 text-white',
  success: 'bg-green-500 hover:bg-green-600 disabled:bg-green-100 text-white',
  danger: 'bg-red-500 hover:bg-red-600 disabled:bg-red-100 text-white',
}

export default function Button({
  children,
  variant = 'default',
  icon,
  loading,
  ...props
}: ButtonProps) {
  return (
    <button disabled={loading} {...props} className={cls(base, variants[variant])}>
      {icon && <span className='mr-2'>{icon}</span>}
      <span>{loading ? "Carregando..." : children}</span>
    </button>
  )
}
