import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const createNotification = createAsyncThunk(
  'notification/create',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.post('/notifications', input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const queryNotifications = createAsyncThunk(
  'notification/query',
  async (params: any, { rejectWithValue }: any) => {
    try {
      const response = await api.get('/notifications', { params })
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const queryNotificationsNoRead = createAsyncThunk(
  'notification/query-noread',
  async (params: any, { rejectWithValue }: any) => {
    try {
      const response = await api.get('/notifications', { params: { isRead: false } })
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const updateNotification = createAsyncThunk(
  'notification/update',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.put(`/notifications/${input.id}`, input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteNotification = createAsyncThunk(
  'notification/delete',
  async (id: any, { rejectWithValue }: any) => {
    try {
      await api.delete(`/notifications/${id}`)
      return { id }
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
