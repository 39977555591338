import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../store'

type PrivateRouteProps = {
    children: ReactNode
}

function PrivateRoute({ children }: PrivateRouteProps) {
    const { isLoggedin, user } = useSelector((state: RootState) => state.auth)

    if (!isLoggedin) {
        return <Navigate to="/entrar" replace />
    }

    return <>{children}</>
}

export default PrivateRoute
