import { RiContactsLine, RiUserStarLine, RiReplyAllLine } from 'react-icons/ri'
import { AiOutlineFunnelPlot } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { GoNote } from 'react-icons/go'
import { MdOutlineSpaceDashboard } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from '../../../store'
import { MenuItem } from '../MenuItem'
import { ProfileDropdown } from '../ProfileDropdown'

export default function Sidebar() {
  const navigate = useNavigate()
  const { user } = useSelector((state: RootState) => state.auth)
  const { notifications, unRead } = useSelector((state: RootState) => state.notification)

  const menu = [
    { icon: <MdOutlineSpaceDashboard />, label: 'Dashboard', href: '/' },
    { icon: <RiContactsLine />, label: 'Contatos', href: '/contatos' },
    // { icon: <RiUserStarLine />, label: 'Usuários', href: '/usuarios' },
    { icon: <AiOutlineFunnelPlot />, label: 'Funil', href: '/funil' },
    { icon: <GoNote />, label: 'Lembretes', href: '/lembretes' },
    { icon: <GoNote />, label: 'Notificações', href: '/notificacoes', notifications: unRead },
    // { icon: <RiReplyAllLine />, label: 'Respostas', href: '/respostas' },
  ]
  const { pathname } = useLocation()

  return (
    <div className="hidden fixed md:flex justify-between h-screen w-52 flex-col border-r">
      <div>
        <div className="h-16 flex items-center border-b px-4">
          <span className='px-2 py-1 text-xs bg-blue-100 text-blue-500 rounded-full'>BETA</span>
        </div>
        <div className="p-4">
          {menu.map(item => (
            <MenuItem
              active={pathname === item?.href}
              key={item?.label}
              icon={item?.icon}
              label={item!.label}
              onClick={() => navigate(item!.href)}
              notifications={item.notifications}
            />
          ))}
        </div>
      </div>
      <div className="p-4">
        <ProfileDropdown user={user} />
      </div>
    </div>
  )
}
