import cls from 'classnames'
import { ReactNode, useRef } from 'react'
import { MdClose } from 'react-icons/md'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

type ModalProps = {
  isOpen: boolean
  onClose(): void
  title?: string
  children: ReactNode
}

const base = 'inset-0 bg-gray-500 bg-opacity-75 transition-opacity'

export default function Modal({
  isOpen,
  onClose,
  title,
  children,
}: ModalProps) {
  const ref = useRef()
  useOnClickOutside(ref, () => onClose())

  return (
    <div className={cls(base, isOpen ? 'fixed' : 'hidden')}>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div ref={ref as any} className="bg-white">
              {title && (
                <div className="border-b p-4 flex items-center justify-between">
                  <h3 className="font-bold">{title}</h3>
                  <div onClick={onClose} className='cursor-pointer'>
                    <MdClose size={16} />
                  </div>
                </div>
              )}
              <div className="p-4">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
