import React from 'react'

type TopBarProps = {
  title: string
}

export default function TopBar({ title }: TopBarProps) {
  return (
    <div className="fixed top-0 w-full bg-white flex items-center px-6 h-16 border-b">
      <span className="text-lg font-bold">{title}</span>
    </div>
  )
}
