import { useEffect, useRef, useState } from 'react'
import cls from 'classnames'
import { RiCloseFill } from 'react-icons/ri'
import { funnelColors } from '../../../utils/colors'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { useController } from 'react-hook-form'

type IOption = {
  label: string
  value: string
  color: string
}

type SelectTagProps = {
  label?: string
  placeholder?: string
  name: string
  type?: string
  error?: string
  required?: boolean
  defaultValue?: any
  options: IOption[]
  control: any
}

function getBackground(hex: string) {
  return funnelColors.find(c => c.color === hex)?.background
}

export default function SelectTag({
  label,
  placeholder,
  name,
  control,
  error,
  required,
  defaultValue,
  options,
}: SelectTagProps) {
  const { field } = useController({ name, control })
  const [open, setOpen] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<any>(null)
  const ref = useRef()
  useOnClickOutside(ref, () => setOpen(false))

  useEffect(() => {
    if (defaultValue) {
      const opt = options.find(o => o.value === defaultValue.id)
      setCurrentValue(opt)
    }
  }, [defaultValue])

  function handleChange(option: any) {
    field.onChange(option ? option.value : null)
    setCurrentValue(option)
    setOpen(false)
  }

  return (
    <div className="mb-2 w-full">
      {label && <div className="mb-1 text-sm">{label}</div>}
      <div className="relative">
        <div
          onClick={() => setOpen(true)}
          style={{
            backgroundColor: currentValue
              ? getBackground(currentValue?.color)
              : '',
          }}
          className="w-full cursor-pointer rounded-md
        bg-gray-200 px-4 py-2 font-medium outline-none"
          placeholder={placeholder}
          defaultValue={defaultValue}
        >
          <span style={{ color: currentValue?.color }}>
            {currentValue?.label ?? placeholder ?? 'Escolha uma opção'}
          </span>
        </div>
        <div
          ref={ref as any}
          style={{
            zIndex: 999,
          }}
          className={cls(
            'absolute top-12 w-full rounded bg-white p-2 shadow',
            open ? '' : 'hidden'
          )}
        >
          {options.map(option => (
            <div
              onClick={() => handleChange(option)}
              key={option.value}
              style={{
                backgroundColor: getBackground(option.color),
                color: option.color,
              }}
              className="mb-2 w-full cursor-pointer rounded px-6 py-2"
            >
              <span>{option.label}</span>
            </div>
          ))}
          <div
            onClick={() => handleChange(null)}
            className="flex w-full cursor-pointer items-center justify-between rounded bg-gray-200 px-6 py-2"
          >
            <span>Remover</span>
            <span>{<RiCloseFill />}</span>
          </div>
        </div>
      </div>
      <p className="font-bold text-red-400">{error}</p>
    </div>
  )
}
