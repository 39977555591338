import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../store'

type PublicRouteProps = {
    children: ReactNode
}

function PublicRoute({ children }: PublicRouteProps) {
    const { isLoggedin } = useSelector((state: RootState) => state.auth)

    if (isLoggedin) {
        return <Navigate to="/" replace />
    }

    return <>{children}</>
}

export default PublicRoute
