import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from './slices/auth'
import AlertSlice from './slices/alert'
import ContactSlice from './slices/contact'
import FunnelSlice from './slices/funnel'
import UserSlice from './slices/user'
import ReplySlice from './slices/reply'
import ReminderSlice from './slices/reminder'
import NotificationSlice from './slices/notification'

const reducer = {
  auth: AuthSlice.reducer,
  alert: AlertSlice.reducer,
  funnel: FunnelSlice.reducer,
  contact: ContactSlice.reducer,
  user: UserSlice.reducer,
  reply: ReplySlice.reducer,
  reminder: ReminderSlice.reducer,
  notification: NotificationSlice.reducer
}

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
})
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export default store
