import { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  UsersTable,
  Container,
  TopBar,
  AddUserModal,
} from '../../components/common'
import Main from '../../components/common/Main'
import { Button } from '../../components/ui'
import { AppDispatch, RootState } from '../../store'
import { queryUsers } from '../../store/actions/user'

export default function ContactsPage() {
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState<boolean>(false)
  const { users } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(queryUsers({}))
  }, [])

  return (
    <>
      <TopBar title="Usuários" />
      <Main>
        <Container>
          <div className="my-6 flex items-center justify-end">
            <Button
              onClick={() => setOpen(true)}
              icon={<FiPlus />}
              variant="primary"
            >
              Add Usuário
            </Button>
          </div>

          <UsersTable users={users} />
        </Container>
      </Main>
      <AddUserModal isOpen={open} onClose={() => setOpen(false)} />
    </>
  )
}
