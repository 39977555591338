import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { FaCheck } from 'react-icons/fa'
import { AppDispatch, RootState } from '../../../../store'
import { createFunnel } from '../../../../store/actions/funnel'
import { alertActions } from '../../../../store/slices/alert'
import { Button, Modal, TextField } from '../../../ui'
import { funnelColors } from '../../../../utils/colors'

type NewFunnelModalProps = {
  isOpen: boolean
  onClose(): void
}

export default function NewFunnelModal({
  isOpen,
  onClose,
}: NewFunnelModalProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.auth)
  const { register, handleSubmit, resetField } = useForm()
  const [selectedColor, setSelectedColor] = useState<string>('#475569')

  async function onSubmit(values: any) {
    Object.assign(values, { color: selectedColor, companyId: user?.companyId })
    const response = await dispatch(createFunnel(values))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Funil criado com sucesso.',
        })
      )
      onClose()
      resetField("name")
    } else {
      dispatch(
        alertActions.createAlert({
          type: 'danger',
          title: 'Ops...',
          message: 'Funil ocorreu algum erro.',
        })
      )
    }
  }

  const colorBase =
    'flex items-center justify-center ml-2 cursor-pointer hover:border-2 hover:shadow-md hover:border-white w-6 h-6 rounded-full bg-gray-400'

  return (
    <Modal title="Novo Funil" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField name="name" label="Nome" register={register} />
        <div className="mt-4 flex">
          {funnelColors.map(color => (
            <div
              onClick={() => setSelectedColor(color.color)}
              key={color.color}
              style={{ backgroundColor: color.color }}
              className={colorBase}
            >
              {selectedColor === color.color && <FaCheck color="#fff" />}
            </div>
          ))}
        </div>
        <div className="mt-4">
          <Button type="submit" variant="primary">
            Adicionar
          </Button>
        </div>
      </form>
    </Modal>
  )
}
