import { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { BiSearch } from 'react-icons/bi'
import { IContact } from '../../../types/contact'

type IOption = {
  label: string
  value: any
}

type SearchContactProps = {
  label?: string
  placeholder?: string
  control?: any
  name: string
  type?: string
  error?: string
  required?: boolean
  defaultValue?: string
  onSearch(text: string): void
  options: IOption[]
}

export default function SearchContact({
  label,
  placeholder,
  name,
  type = 'text',
  error,
  required,
  defaultValue,
  onSearch,
  options,
  control,
}: SearchContactProps) {
  const [selected, setSelected] = useState<IContact | null>(null)
  const [open, setOpen] = useState(false)
  const [text, setText] = useState<string>('')
  const { field } = useController({
    control,
    name,
  })

  function handleChange(e: any) {
    const { value } = e.target
    setText(value)
    onSearch(value)
  }

  function onSelect(value: IContact) {
    field.onChange(value.id)
    setText(value.name)
    setOpen(false)
    setSelected(value)
  }

  useEffect(() => {
    if (options.length) {
      setOpen(true)
    }
  }, [options])


  return (
    <div className="mb-2 w-full">
      {label && <div className="mb-1 text-sm">{label}</div>}
      <div className="relative">
        <div
          className="flex w-full items-center rounded-md
        bg-gray-200"
        >
          {selected && <div className="ml-2">
            <img
              className="h-7 w-7 rounded-md"
              src={selected.avatar}
            />
          </div>}
          <div className="flex w-full items-center  px-4 py-2">
            <input
              type={type}
              className="w-full bg-gray-200 font-medium outline-none"
              placeholder={placeholder}
              defaultValue={defaultValue}
              onChange={handleChange}
              value={text}
            />
            <BiSearch />
          </div>
        </div>
        {open ? (
          <div className="absolute mt-1 max-h-36 w-full rounded-md bg-white shadow-lg">
            {options.map(option => (
              <div
                onClick={() => onSelect(option.value)}
                key={option.value}
                className="my-1 flex cursor-pointer items-start px-4 py-1 hover:bg-gray-100"
              >
                <img
                  src={option.value.avatar}
                  className="h-8 w-8 rounded-md bg-gray-300"
                />
                <div className="ml-2">
                  <h4 className="text-xs font-semibold">{option.value.name}</h4>
                  <p className="text-xs">{option.value.mobileNumber}</p>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <p className="font-bold text-red-400">{error}</p>
    </div>
  )
}
