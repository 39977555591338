import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const createFunnel = createAsyncThunk(
  'funnel/create',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.post('/funnels', input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const queryFunnels = createAsyncThunk(
  'funnel/query',
  async (params: any, { rejectWithValue }: any) => {
    try {
      const response = await api.get('/funnels', { params })
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const updateFunnel = createAsyncThunk(
  'funnel/update',
  async (input: any, { rejectWithValue }: any) => {
    try {
      const response = await api.put(`/funnels/${input.id}`, input)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteFunnel = createAsyncThunk(
  'funnel/delete',
  async (id: any, { rejectWithValue }: any) => {
    try {
      await api.delete(`/funnels/${id}`)
      return { id }
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        console.log(error.response)
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
