import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FiCheckCircle, FiInfo } from 'react-icons/fi'
import { RootState } from '../../../store'

export default function Alert() {
  const { alerts } = useSelector((state: RootState) => state.alert)
  const [alert, setAlert] = useState({ type: '', message: '', title: '' })
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1])
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    }
  }, [alerts])

  return show ? (
    <div
      style={{ zIndex: 999 }}
      className="absolute top-2 right-2 flex rounded-md bg-white p-4 shadow-lg"
    >
      <div className="flex items-center justify-center">
        {alert.type === 'success' ? (
          <FiCheckCircle size={18} color="#10b981" />
        ) : (
          <FiInfo size={18} color="#f43f5e" />
        )}
      </div>
      <div className="ml-4 w-52">
        <h4 className="font-bold text-gray-800">{alert.title}</h4>
        <p className="text-gray-700">{alert.message}</p>
      </div>
    </div>
  ) : null
}
