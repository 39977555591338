import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RiProfileLine } from 'react-icons/ri'
import { MdDelete } from 'react-icons/md'
import { DeleteModal, MenuItem, TopBar } from '../../components/common'
import { IContact } from '../../types/contact'
import api from '../../utils/api'
import { formatPhone } from '../../utils/format'
import { useForm } from 'react-hook-form'
import {
  Button,
  MaskTextField,
  ProfileSkeleton,
  Select,
  SelectTag,
  SimpleSkeleton,
  TextArea,
  TextField,
} from '../../components/ui'
import { useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { deleteContact, updateContact } from '../../store/actions/contact'
import { alertActions } from '../../store/slices/alert'
import { queryFunnels } from '../../store/actions/funnel'
import { useSelector } from 'react-redux'
import Main from '../../components/common/Main'
import { IUser } from '../../types/user'

export default function ContactViewPage() {
  const navigate = useNavigate()
  const [users, setUsers] = useState<IUser[]>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { loading } = useSelector((state: RootState) => state.contact)
  const { funnels } = useSelector((state: RootState) => state.funnel)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [contact, setContact] = useState<IContact | null>(null)

  async function fetchContact() {
    const response = await api.get(`/contacts/${id}`)
    setContact(response.data.contact)
  }

  async function fetchUsers() {
    const response = await api.get(`/users`)
    setUsers(response.data.results)
  }

  async function onSubmit(values: any) {
    const input = Object.assign({}, contact, values)
    const response = await dispatch(updateContact(input))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Contato atualizado com sucesso.',
        })
      )
      setContact(response.payload.contact)
    }
  }

  async function handleDelete() {
    const response = await dispatch(deleteContact(contact?.id))
    if (response.meta.requestStatus === 'fulfilled') {
      navigate('/contatos')
    }
  }

  useEffect(() => {
    dispatch(queryFunnels({}))
    fetchContact()
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <div className="h-full">
      <TopBar title="Dados do Contato" />
      <Main>
        <div className="flex h-full">
          <div className="h-full w-44 border-r p-2">
            {contact ? (
              <>
                <img src={contact.avatar} className="h-40 w-40"></img>
                <div className="mt-2">
                  <h3 className="font-bold">{contact?.name}</h3>
                  <p>{formatPhone(contact)}</p>
                </div>
                <div className="mt-4">
                  <MenuItem active icon={<RiProfileLine />} label="Perfil" />
                </div>
              </>
            ) : (
              <ProfileSkeleton />
            )}
          </div>
          <div className="flex-1 p-8">
            {contact ? (
              <form className="max-w-xs" onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  name="name"
                  label="Nome"
                  register={register}
                  defaultValue={contact.name}
                  required
                  error={errors.name && 'Insira um nome'}
                />
                <SelectTag
                  name="funnel"
                  label="Funil"
                  control={control}
                  defaultValue={contact.funnel}
                  required
                  options={funnels.map(funnel => ({
                    label: funnel.name,
                    value: funnel.id,
                    color: funnel.color,
                  }))}
                  error={errors.funnel && 'Insira um nome'}
                />
                <Select
                  name="responsible"
                  label="Responsável"
                  control={control}
                  defaultValue={contact.responsible}
                  required
                  options={users.map(responsible => ({
                    label: responsible.name,
                    value: responsible.id
                  }))}
                  error={errors.responsible && 'Insira um responsável'}
                />
                <MaskTextField
                  options={{
                    phone: true,
                    phoneRegionCode: 'BR',
                  }}
                  placeholder="Ex: 99 99999 9999"
                  name="mobileNumber"
                  label="Celular"
                  control={control}
                  defaultValue={contact.mobileNumber}
                />
                <TextField
                  name="email"
                  label="Email"
                  register={register}
                  defaultValue={contact.email}
                />
                <TextField
                  name="city"
                  label="Cidade"
                  register={register}
                  defaultValue={contact.city}
                />
                <TextArea
                  name="address"
                  label="Endereço"
                  register={register}
                  defaultValue={contact.address}
                />
                {/* <TextArea
                  name="notes"
                  label="Notas"
                  register={register}
                  defaultValue={contact.notes}
                /> */}
                <div className="mt-6 flex items-center justify-between">
                  <Button loading={loading} variant="primary">Salvar</Button>
                  <button
                    onClick={() => setDeleteModal(true)}
                    type="button"
                    className="rounded-md bg-red-100 p-2 text-red-500 hover:text-red-600"
                  >
                    <MdDelete size={20} />
                  </button>
                </div>
              </form>
            ) : (
              <SimpleSkeleton />
            )}
          </div>
        </div>
      </Main>
      <DeleteModal
        onSubmit={handleDelete}
        description="A exclusão desse contato é irreversivel"
        title="Deseja excluir esse contato?"
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </div>
  )
}
