import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'
import { FaCheck } from 'react-icons/fa'
import { Container, DeleteModal, TopBar } from '../../components/common'
import Main from '../../components/common/Main'
import {
  Button,
  ProfileSkeleton,
  TextArea,
  TextField,
} from '../../components/ui'
import { IReply } from '../../types/reply'
import api from '../../utils/api'
import { useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { deleteReply, updateReply } from '../../store/actions/reply'
import { useSelector } from 'react-redux'
import { alertActions } from '../../store/slices/alert'

export default function ReplyViewPage() {
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const auth = useSelector((state: RootState) => state.auth)
  const [reply, setReply] = useState<IReply | null>(null)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  async function fetchReply() {
    const response = await api.get(`/replies/${id}`)
    setReply(response.data.reply)
  }

  async function handleDelete() {
    const response = await dispatch(deleteReply(reply?.id))
    if (response.meta.requestStatus === 'fulfilled') {
      navigate('/respostas')
    }
  }

  async function onSubmit(values: any) {
    const input = Object.assign({}, reply, values)
    const response = await dispatch(updateReply(input))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Resposta atualizado',
        })
      )
    } else {
      dispatch(
        alertActions.createAlert({
          type: 'danger',
          title: 'Ops!',
          message: 'Ocorreu um erro inesperado',
        })
      )
    }
  }

  useEffect(() => {
    fetchReply()
  }, [])

  useEffect(() => {
    if (reply) {
      setValue('name', reply.name)
      setValue('message', reply.message)
    }
  }, [reply])

  return (
    <>
      <TopBar title="Detalhes do Resposta" />
      <Main>
        <Container>
          <div className="max-w-md">
            {reply ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  name="name"
                  label="Nome"
                  required
                  error={errors?.name && 'Informe o nome'}
                  register={register}
                />
                <TextArea
                  name="message"
                  label="Mensagem"
                  required
                  error={errors?.message && 'Informe a mensagem'}
                  register={register}
                />
                <div className="mt-6 flex items-center justify-between">
                  <Button variant="primary">Salvar</Button>
                  <button
                    onClick={() => setDeleteModal(true)}
                    type="button"
                    className="rounded-md bg-red-100 p-2 text-red-500 hover:text-red-600"
                  >
                    <MdDelete size={20} />
                  </button>
                </div>
              </form>
            ) : (
              <ProfileSkeleton />
            )}
          </div>
        </Container>
      </Main>
      <DeleteModal
        onSubmit={handleDelete}
        description="A exclusão dessa resposta é irreversivel"
        title="Deseja excluir essa resposta?"
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </>
  )
}
