import { useNavigate } from 'react-router-dom'
import { IFunnel } from '../../../../types/funnel'
import NoData from '../../../../../public/assets/no-data.svg'

type FunnelsTableProps = {
  funnels: IFunnel[]
}

export default function FunnelsTable({ funnels }: FunnelsTableProps) {
  const navigate = useNavigate()
  return (
    <div>
      <table className="w-full table-auto">
        <thead>
          <tr className="border-b text-left">
            <th>Cor</th>
            <th>Nome</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {funnels.map(funnel => (
            <tr key={funnel.id} className="hover:bg-gray-100">
              <td className="py-4 text-sm">
                <div>
                  <div
                    style={{ backgroundColor: funnel.color }}
                    className="h-8 w-8 rounded-full"
                  />
                </div>
              </td>
              <td className="py-4 text-sm">
                <div onClick={() => navigate(`/funil/v/${funnel.id}`)}>
                  <span className="cursor-pointer font-bold">
                    {funnel.name}
                  </span>
                </div>
              </td>
              <td className="py-4 text-sm">
                <div className="flex"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!funnels.length && (
        <div className="flex w-full flex-col items-center justify-center py-10">
          <img className="w-40" src={NoData} />
          <span className="mt-4 text-lg font-bold">
            Nenhum funil encontrado.
          </span>
        </div>
      )}
    </div>
  )
}
