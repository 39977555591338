
type TextFieldProps = {
  label?: string
  placeholder?: string
  register?: any
  name: string
  type?: string
  error?: string
  required?: boolean
  defaultValue?: string
}

export default function TextField({ label, placeholder, name, register, type = "text", error, required, defaultValue }: TextFieldProps) {
  return (
    <div className="w-full mb-2">
      {label && <div className="text-sm mb-1">{label}</div>}
      <input
        type={type}
        className="w-full bg-gray-200
        rounded-md outline-none px-4 py-2 font-medium"
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...register(name, { required })}
      />
      <p className="text-red-400 font-bold">{error}</p>
    </div>
  )
}
