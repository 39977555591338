import axios from 'axios'
import env from '../../env'

const token = localStorage.getItem('@app/token')

const api = axios.create({
  baseURL: env.apiUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})


api.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/entrar'
    }
    return Promise.reject(error);
  },
)

api.interceptors.request.use(
  (request: any) => {
    request.headers['x-company-id'] = localStorage.getItem("@app/companyId")
    return request
  }
)

export default api
