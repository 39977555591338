import React, { ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from '../../../store'
import { getMe } from '../../../store/actions/auth'
import { queryNotifications, queryNotificationsNoRead } from '../../../store/actions/notification'
import { SimpleSkeleton } from '../../ui'
import { Sidebar } from '../Sidebar'

const notificationPollingInterval = 60000 //in ms

export default function AdminLayout() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isLoggedin, user, token } = useSelector(
    (state: RootState) => state.auth
  )
  const dispatch: AppDispatch = useDispatch()

  async function fetchNotifications() {
    dispatch(queryNotificationsNoRead({ isRead: false }))
    setTimeout(fetchNotifications, notificationPollingInterval);
  }

  useEffect(() => {
    if (isLoggedin && token) {
      dispatch(getMe())
    }
  }, [isLoggedin, token])

  useEffect(() => {
    fetchNotifications()
  }, [])

  useEffect(() => {
    if (user && !user?.updatedPassword) {
      navigate('/nova-senha')
    }
  }, [user, pathname])

  return user ? (
    <div className="">
      <Sidebar />
      <div className="h-screen md:ml-52">
        <Outlet />
      </div>
    </div>
  ) : (
    <SimpleSkeleton />
  )
}
