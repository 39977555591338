import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { MdBusiness } from 'react-icons/md'
import { FaMoneyBill } from 'react-icons/fa'
import Main from '../Main'
import { MenuItem } from '../MenuItem'
import { TopBar } from '../TopBar'

export default function SettingLayout() {
  const { pathname } = useLocation()
  console.log("pathname", pathname)
  return (
    <>
      <TopBar title="Configurações" />
      <Main>
        <div className='flex h-full'>
          <div className='w-52 px-2 py-4 border-r h-full'>
            <div className="">
              <MenuItem active={pathname === "/configuracoes/empresa"} icon={<MdBusiness />} label="Empresa" />
              <MenuItem active={pathname === "/configuracoes/inscricao"} icon={<FaMoneyBill />} label="Inscrição" />
            </div>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </Main>
    </>
  )
}
