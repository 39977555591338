import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../../../store'
import { alertActions } from '../../../../store/slices/alert'
import { Button, Modal, TextField } from '../../../ui'
import { createUser } from '../../../../store/actions/user'

type AddUserModelProps = {
  isOpen: boolean
  onClose(): void
}

export default function AddUserModel({ isOpen, onClose }: AddUserModelProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.auth)
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm()

  async function onSubmit(values: any) {
    Object.assign(values, { companyId: user?.companyId })
    const response = await dispatch(createUser(values))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Usuário criado com sucesso.',
        })
      )
      onClose()
      resetField('name')
    } else {
      dispatch(
        alertActions.createAlert({
          type: 'danger',
          title: 'Ops...',
          message: 'Usuário ocorreu algum erro.',
        })
      )
    }
  }

  const colorBase =
    'flex items-center justify-center ml-2 cursor-pointer hover:border-2 hover:shadow-md hover:border-white w-6 h-6 rounded-full bg-gray-400'

  return (
    <Modal title="Novo Usuário" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="name"
          label="Nome"
          register={register}
          required
          error={errors.name && 'Informe o nome'}
        />
        <TextField
          name="email"
          label="Email"
          register={register}
          required
          error={errors.email && 'Informe o email'}
        />
        <p>A senha de acesso irá para o email inserido.</p>
        <div className="mt-4">
          <Button type="submit" variant="primary">
            Adicionar
          </Button>
        </div>
      </form>
    </Modal>
  )
}
