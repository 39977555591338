import React from 'react'
import { useSelector } from 'react-redux'
import { Container, TopBar } from '../../components/common'
import Main from '../../components/common/Main'
import { SimpleSkeleton } from '../../components/ui'
import { RootState } from '../../store'

export default function DashboardPage() {
  const { user } = useSelector((state: RootState) => state.auth)
  return (
    <>
      <TopBar title="Dashboard" />
      <Main>
        <Container>
          {user ? (
            <div>
              <h3 className="text-xl font-bold text-gray-700">
                Olá, <span>{user.name}</span>
              </h3>
            </div>
          ) : (
            <SimpleSkeleton />
          )}
        </Container>
      </Main>
    </>
  )
}
