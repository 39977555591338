import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Container, TopBar } from '../../components/common'
import Main from '../../components/common/Main'
import { Button, TextField } from '../../components/ui'
import { AppDispatch, RootState } from '../../store'
import { updateMe } from '../../store/actions/auth'
import { updateUser } from '../../store/actions/user'
import { alertActions } from '../../store/slices/alert'
import { authActions } from '../../store/slices/auth'

export default function ProfilePage() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const dispatch: AppDispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)

  async function onSubmit(values: any) {
    const input = Object.assign({}, user, values)
    const response = await dispatch(updateMe(input))
    if (response.meta.requestStatus === "fulfilled") {
      await dispatch(alertActions.createAlert({
        type: "success",
        title: "Sucesso!",
        message: "Usuário atualizado"
      }))
    }
  }

  useEffect(() => {
    if (user) {
      setValue("name", user.name)
      setValue("email", user.email)
    }
  }, [user])

  return (
    <>
      <TopBar title="Meu Perfil" />
      <Main>
        <Container>
          <div className="max-w-sm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                name="name"
                label="Nome"
                required
                error={errors?.name && 'Informe o nome'}
                register={register}
              />
              <TextField
                name="email"
                label="Email"
                required
                error={errors?.name && 'Informe o email'}
                register={register}
              />
              <div className="mt-6">
                <Button variant="primary">Salvar</Button>
              </div>
            </form>
          </div>
        </Container>
      </Main>
    </>
  )
}
