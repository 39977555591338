import { createSlice } from '@reduxjs/toolkit'
import { IUser } from '../../types/user'
import { authLogin, getMe, updateMe } from '../actions/auth'

interface InitialState {
  loading: boolean
  token: string | null
  user: IUser | null
  isLoggedin: boolean
}

const initialState: InitialState = {
  loading: false,
  token: localStorage.getItem('@app/token'),
  user: null,
  isLoggedin: !!localStorage.getItem('@app/token')
}

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signout: (state: any) => {
      localStorage.clear()
      window.location.href = '/entrar'

      state.alerts.push({
        token: null,
        user: null,
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(authLogin.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.token = payload.accessToken
        state.user = payload.user
        state.isLoggedin = true
      }),
      builder.addCase(authLogin.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(getMe.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(getMe.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.user = payload.user
      }),
      builder.addCase(getMe.rejected, (state: any) => {
        state.loading = false
      })

    builder.addCase(updateMe.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(updateMe.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.user = payload.user
      }),
      builder.addCase(updateMe.rejected, (state: any) => {
        state.loading = false
      })
  },
})

export const authActions = AuthSlice.actions

export default AuthSlice
