import { createReminder, deleteReminder, updateReminder } from './../actions/reminder';
import { createSlice } from '@reduxjs/toolkit'
import { IReminder } from '../../types/reminder'
import { queryReminders } from '../actions/reminder'

interface InitialState {
  loading: boolean
  reminders: IReminder[]
}

const initialState: InitialState = {
  loading: false,
  reminders: []
}

export const ReminderSlice = createSlice({
  name: 'reminder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createReminder.pending, (state: any) => {
      state.loading = true
    }),
      builder.addCase(createReminder.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.reminders]
        arr.push(payload.reminder)
        state.reminders = arr
      }),
      builder.addCase(createReminder.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(queryReminders.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(queryReminders.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.reminders = payload.results
      }),
      builder.addCase(queryReminders.rejected, (state: any) => {
        state.loading = false
      }),

      builder.addCase(updateReminder.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(updateReminder.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.reminders]
        const idx = state.reminders.findIndex((reminder: IReminder) => reminder.id === payload.reminder.id)
        arr[idx] = payload.reminder
        state.reminders = arr
      }),
      builder.addCase(updateReminder.rejected, (state: any) => {
        state.loading = false
      })

      builder.addCase(deleteReminder.pending, (state: any) => {
        state.loading = true
      }),
      builder.addCase(deleteReminder.fulfilled, (state: any, { payload }) => {
        state.loading = false
        const arr = [...state.reminders]
        const idx = state.reminders.findIndex((reminder: IReminder) => reminder.id === payload.id)
        arr.splice(idx, 1)
        state.reminders = arr
      }),
      builder.addCase(deleteReminder.rejected, (state: any) => {
        state.loading = false
      })
  },
})

export const reminderActions = ReminderSlice.actions

export default ReminderSlice
