import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../../../store'
import { createContact } from '../../../../store/actions/contact'
import { alertActions } from '../../../../store/slices/alert'
import { Button, MaskTextField, Modal, TextField } from '../../../ui'

type NewContactModalProps = {
  isOpen: boolean
  onClose(): void
}

export default function NewContactModal({
  isOpen,
  onClose,
}: NewContactModalProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.auth)
  const { register, handleSubmit, control } = useForm()

  async function onSubmit(values: any) {
    Object.assign(values, {
      countryCode: '+55',
      companyId: user?.companyId,
      mobileNumber: values.mobileNumber.replaceAll(' ', ''),
    })
    const response = await dispatch(createContact(values))
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(
        alertActions.createAlert({
          type: 'success',
          title: 'Sucesso!',
          message: 'Contato criado com sucesso.',
        })
      )
      onClose()
    } else {
      dispatch(
        alertActions.createAlert({
          type: 'danger',
          title: 'Ops...',
          message: 'Contato ocorreu algum erro.',
        })
      )
    }
  }

  return (
    <Modal title="Novo Contato" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField name="name" label="Nome" register={register} />
        <MaskTextField
          options={{
            phone: true,
            phoneRegionCode: 'BR',
          }}
          placeholder="Ex: 99 99999 9999"
          name="mobileNumber"
          label="Celular"
          control={control}
        />
        <div className="mt-4">
          <Button type="submit" variant="primary">
            Adicionar
          </Button>
        </div>
      </form>
    </Modal>
  )
}
