import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.br'
import { Controller, useController } from 'react-hook-form'

type MaskTextFieldProps = {
  label?: string
  placeholder?: string
  control: any
  name: string
  type?: string
  error?: string
  required?: boolean
  defaultValue?: string
  options: object
}

export default function MaskTextField({
  options,
  label,
  placeholder,
  name,
  control,
  type = 'text',
  error,
  required,
  defaultValue,
}: MaskTextFieldProps) {
  return (
    <div className="mb-2 w-full">
      {label && <div className="mb-1 text-sm">{label}</div>}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ref } }) => (
          <Cleave
            className="w-full rounded-md
          bg-gray-200 px-4 py-2 font-medium outline-none"
            ref={ref}
            onChange={onChange}
            value={value}
            options={options}
          />
        )}
      />
      {/* <Cleave
        options={options}
        type={type}
        className="w-full bg-gray-200
        rounded-md outline-none px-4 py-2 font-medium"
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...register(name, { required })}
      /> */}
      <p className="font-bold text-red-400">{error}</p>
    </div>
  )
}
