import React, { ReactNode } from 'react'
import cls from 'classnames'
import { useNavigate } from 'react-router-dom'

type MenuItemProps = {
  icon?: ReactNode
  label: string
  onClick?(): void
  notifications?: number
  active?: boolean
}

const base = 'mb-2 cursor-pointer rounded-md p-2 hover:bg-gray-100'
const activeStyle = 'bg-gray-100'

export default function MenuItem({
  active,
  icon,
  label,
  onClick,
  notifications,
}: MenuItemProps) {
  return (
    <div onClick={onClick} className={cls(base, active && activeStyle)}>
      <div className="flex items-center text-sm">
        {icon && <span className="mr-2">{icon}</span>}
        <span>{label}</span>
        {notifications && notifications > 0 ? (
          <span className="ml-2 flex h-5 w-5 animate-ping items-center justify-center rounded-full bg-red-500 text-xs text-white">
            {notifications}
          </span>
        ) : null}
      </div>
    </div>
  )
}
